.Mui-selected-conf {
  font-weight: 900;
  color: #27b279;
}
.MuiFormControl-root {
  margin-bottom: 2px !important;
  margin-top: 2px !important;
}
.adjustLabelSelect {
  transform: translate(8px, 14.5px) scale(0.75) !important;
  background: #ffffff !important;
  padding-left: 8px !important;
  z-index: 1;
  width: 113px;
}
.adjustLabelSelectSex {
  transform: translate(8px, 14.5px) scale(0.75) !important;
  background: #ffffff !important;
  padding-left: 8px !important;
  z-index: 1;
  width: 55px;
}

.adjustLabelSelectEstCi {
  transform: translate(8px, 14.5px) scale(0.75) !important;
  background: #ffffff !important;
  padding-left: 8px !important;
  z-index: 1;
  width: 105px;
}
button:active {
  transform: scale(0.95);
  opacity: 0.7;

  -webkit-transition: all 100ms ease;
  -moz-transition: all 100ms ease;
  -ms-transition: all 100ms ease;
  -o-transition: all 100ms ease;
  transition: all 100ms ease;
}
.fc-event-time {
  font-weight: 900;
}

.shine-effect {
  animation: shine 1s infinite;
}

@keyframes shine {
  50% {
    opacity: 0;
  }
}

.MuiTab-wrapper {
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
}
.MuiTab-wrapper svg {
  margin-right: 10px !important;
  margin-bottom: 1px !important;
}
.MuiAutocomplete-popper {
  z-index: 999999 !important;
  position: absolute !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.MuiAutocomplete-root {
  margin-bottom: 2px !important;
  margin-top: 0px !important;
  z-index: 1;
}

#mui-rte-editor {
  padding-left: 15px !important;
  padding-right: 15px !important;
  position: relative !important;
}

#mui-rte-toolbar {
  background-color: rgb(207, 207, 207);
}
.testeee {
  position: relative;
}
.MuiListItemText-primary {
  font-weight: 700 !important;
}
.activeSelection {
  font-weight: 900;
}

.hiddenText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.constant-tilt-shake {
  animation: tilt-shaking 0.3s infinite;
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.shake {
  animation: shake-animation 4.72s ease infinite;
  transform-origin: 50% 50%;
}

@keyframes shake-animation {
  0% {
    transform: translate(0, 0);
  }
  1.78571% {
    transform: translate(5px, 0);
  }
  3.57143% {
    transform: translate(0, 0);
  }
  5.35714% {
    transform: translate(5px, 0);
  }
  7.14286% {
    transform: translate(0, 0);
  }
  8.92857% {
    transform: translate(5px, 0);
  }
  10.71429% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
× .lineCalendar {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: red;
  transition: top 1s linear; /* Adiciona uma transição suave para a animação */
}
#mui-rte-container {
  margin: 0px 0px 0px 0px !important;
}
.fc .fc-button-primary {
  background-color: #27b279;
  border-color: #1fa26c;
  color: var(--fc-button-text-color);
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: #1a7f55;
  border-color: #1fa26c;
  color: var(--fc-button-text-color);
}

.fc .fc-button-primary:hover {
  background-color: #1a7f55;
  border-color: #1fa26c;
  color: var(--fc-button-text-color);
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translateY(50vh);
    transform: translateY(50vh);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInUp {
  0% {
    -webkit-transform: translateY(50vh);
    transform: translateY(50vh);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.removeBgWhite {
  background-color: white;
  mix-blend-mode: multiply;
}

.area {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.modalSelectionCv {
  width: 80% !important;
  float: inline-end !important;
  right: 0 !important;
  left: unset !important;
}

.modalAgnesAI {
  padding-left: 100% !important;
  float: inline-end !important;
  right: 0 !important;
  left: unset !important;
}

.modalAgnesAI .MuiPaper-root {
  width: 60vw !important;
}

.f10comments p {
  padding-top: 15px !important;
}

.observBox p {
  padding-top: 15px !important;
}

.observ p {
  padding-top: 15px !important;
}

.observVacancy p {
  padding-top: 15px !important;
}

.agnes-ai-markdown h1 {
  font-weight: 900;
  font-size: 20px;
}

.agnes-ai-markdown h2 {
  font-weight: 700;
  font-size: 18px;
}

.agnes-ai-markdown h3 {
  font-weight: 700;
  font-size: 16px;
}

.agnes-ai-markdown h1,
.agnes-ai-markdown h2,
.agnes-ai-markdown h3,
.agnes-ai-markdown h4,
.agnes-ai-markdown h5,
.agnes-ai-markdown h6 {
  padding-bottom: 5px;
}

.agnes-ai-markdown p,
.agnes-ai-markdown ul,
.agnes-ai-markdown ol,
.agnes-ai-markdown hr {
  padding-bottom: 15px;
}

.agnes-ai-markdown ul {
  list-style-type: disc;
  padding-left: 30px;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(0, 0, 0, 0.143);
  animation: animate 5s linear infinite;
  bottom: -150px;
}

.adjustPadding {
  justify-content: center;
  align-items: center;
  color: #1fa26c;
  display: flex;
  border-left: 1px solid #1fa26c;
  border-right: 1px solid #1fa26c;
}

.statusTopText {
  font-weight: 900;
}

.adjustPaddingRight {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}
.statusTop {
  position: absolute;
  top: -17px;
  border-radius: 4px;
  border: 2px solid #1fa26c;
  background: #fff;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 700;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.statusTopVisu {
  position: absolute;
  top: -11px;
  border-radius: 4px;
  background: #fff;
  font-weight: 700;
  background-color: #1fa26c;
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}
.selectionNames {
  position: fixed;
  bottom: 78px;
  padding: 10px;
  margin: 10px;
  margin-left: 23px;
  background: #fff;
  border-radius: 4px;
  width: 96%;
  box-sizing: border-box;
  z-index: 4;
  border: 2px solid #1fa26c;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.stickDivNoProfile {
  position: fixed !important;
  top: calc(40vh - 35px);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: red;
  border-radius: 4px;
}
.stickDivSelectedCan {
  position: fixed !important;
  top: calc(40vh - 35px);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #1fa26c;
  border-radius: 4px;
}

.stickDivLoadingProfile {
  position: fixed !important;
  top: calc(40vh - 35px);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
}

.noProfileDiv {
  position: absolute;
  width: calc(100% - 8px);
  height: 100%;
  z-index: 3;
  top: 8px;
  left: 8px;
  background: #ff00003e;
  backdrop-filter: blur(20px);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  color: #fff;
  font-weight: 900;
  font-size: 19px;
  border-radius: 8px;
}

.loadingProfile {
  position: absolute;
  width: calc(100% - 8px);
  height: 100%;
  z-index: 3;
  top: 8px;
  left: 8px;
  background: #ffffff3e;
  backdrop-filter: blur(20px);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  color: #fff;
  font-weight: 900;
  font-size: 19px;
  border-radius: 8px;
}

.notificationText {
  font-size: 14px !important;
  padding-top: 5px;
  color: #000;
}

.boxTopHisString {
  background: #ffffff3f;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 !important;
  font-size: 15px !important;
  padding-left: 10px !important;
  margin-left: 10px !important;
  margin-top: 8px !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 96%;
  z-index: 4;
  border: 1px solid #eaeaea;

  backdrop-filter: blur(15px);
}

.eventBox {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.adjustWidthLabel label {
  width: 100% !important;
}
.adjustWidthLabel .MuiFormControlLabel-label {
  width: 100% !important;
}

.boxOptionsHis {
  border: 1px solid #1fa26b;
  border-radius: 4px;
  padding-bottom: 15px !important;
  padding-top: 15px !important;
  position: relative;
  background: #fff;
  overflow: hidden;
}

.boxOptionsHisDivStart {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
  background: #ffffff51;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectedCanDiv {
  position: absolute;
  width: calc(100% - 8px);
  height: 100%;
  z-index: 3;
  top: 8px;
  left: 8px;
  background: #1fa26b4c;
  backdrop-filter: blur(20px);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  color: #fff;
  font-weight: 900;
  font-size: 19px;
  border-radius: 8px;
}

#historico_ajax_can {
  min-height: 200px;
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.imageAdj img {
  margin-left: -23px;
  filter: grayscale(117%);
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.css-selector {
  background: linear-gradient(
    90deg,
    #aaaaaa,
    #ffffff,
    #ffffff,
    #dbdbdb,
    #d0d0d0,
    #ffffff,
    #ffffff
  );
  background-size: 1400% 1400%;

  -webkit-animation: SearchingBG 5s ease infinite;
  -moz-animation: SearchingBG 5s ease infinite;
  -o-animation: SearchingBG 5s ease infinite;
  animation: SearchingBG 5s ease infinite;
}

@-webkit-keyframes SearchingBG {
  0% {
    background-position: 70% 0%;
  }
  50% {
    background-position: 31% 100%;
  }
  100% {
    background-position: 70% 0%;
  }
}
@-moz-keyframes SearchingBG {
  0% {
    background-position: 70% 0%;
  }
  50% {
    background-position: 31% 100%;
  }
  100% {
    background-position: 70% 0%;
  }
}
@-o-keyframes SearchingBG {
  0% {
    background-position: 70% 0%;
  }
  50% {
    background-position: 31% 100%;
  }
  100% {
    background-position: 70% 0%;
  }
}
@keyframes SearchingBG {
  0% {
    background-position: 70% 0%;
  }
  50% {
    background-position: 31% 100%;
  }
  100% {
    background-position: 70% 0%;
  }
}
.justfAligcen {
  display: flex;
  justify-content: center;
  align-items: center;
}
.observCv {
  position: fixed;
  bottom: 92px;
  left: 0;
  width: 100%;
  height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px;
  padding-bottom: 60px;
  z-index: 4;
  backdrop-filter: blur(15px);
  border-top: 1px solid #1fa26c;
}

.applyBlur {
  backdrop-filter: blur(15px);
  background: #ffffff47;
}

.observCv code {
  display: block;
  width: 100%;
}
.pfisicoCv {
  position: absolute;
}
.dtAtualizada {
  position: absolute;
  right: 50px;
  margin-top: 50px;
  font-weight: 600;
  padding: 5px 10px 5px 10px;
  border-radius: 4px;
  display: flex;
  color: #fff;
  background: #1f6ba2;
  justify-content: center;
}

.dtEntre {
  position: absolute;
  right: 50px;
  margin-top: 100px;
  font-weight: 600;
  padding: 5px 10px 5px 10px;
  border-radius: 4px;
  display: flex;
  color: #000;
  background: #e8e007;
  justify-content: center;
}

.dtInc {
  position: absolute;
  background: #1fa26c;
  color: #fff;
  right: 50px;
  font-weight: 600;
  padding: 5px 10px 5px 10px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}
.dtIncTitle {
  font-size: 12px;
  position: absolute;
  top: -10px;
  background: #1fa26c;
  border-radius: 4px;
  padding: 0px 5px 0px 5px;
}
.dtAtualizadaTitle {
  font-size: 12px;
  position: absolute;
  top: -10px;
  background: #1f6ba2;
  border-radius: 4px;
  padding: 0px 5px 0px 5px;
}

.dtEntreTitle {
  font-size: 12px;
  position: absolute;
  top: -10px;
  background: #e8e007;
  border-radius: 4px;
  padding: 0px 5px 0px 5px;
}

#label-prop-vacancy {
  top: -2px;
  color: #607d8b;
  font-family: Nunito, sans-serif;
  font-weight: 400;
  font-size: 1rem !important;
  line-height: 1.4375em !important;
  letter-spacing: 0;
  padding: 0;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 24px);
  position: absolute;
  left: 0;
}

.dashedItem {
  border: 1px dotted #1fa26c;
}

.jobsTabs {
  width: 100%;
  display: grid;
  gap: 5px;
  align-content: start;
  justify-content: start;
  justify-items: start;
  padding-top: 15px;
  padding-bottom: 15px;
}
.listOnline {
  padding: 10px !important;
}

#mui-rte-container {
  background: #fff;
  border: 1px solid #c4c4c4;
  min-height: 102px;
  margin-bottom: 15px !important;
  border-radius: 4px;
}
.observBox {
  border: 1px solid #c4c4c4;
  margin-top: 16px;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 500px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.MuiFilledInput-underline:before {
  border-bottom: 0px !important;
}

.MuiTypography-h5 {
  font-size: 1rem !important;
}
.MuiButton-sizeSmall {
  height: 40px;
}
textarea {
  padding-right: 5px !important;
}
.typoTitle {
  border-left: 3px solid;
  padding-left: 5px;
}

.titlef10cli {
  background: #ffffff6b;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  backdrop-filter: blur(4px);
  padding: 7px;
  width: 104%;
}

.observVacancy {
  width: 107%;
  height: 100%;
  max-height: 80vh;
  overflow-y: auto;
  margin-top: -51px;
  padding-top: 50px;
  padding-bottom: 78px;
}

.codigoPri input:disabled {
  color: #000 !important;
  -webkit-text-fill-color: #000 !important;
  font-weight: 900 !important;
  border-radius: 6px;
  background-color: #e8c4c4;
  border: 1px dashed #fb0202;
  height: 20px;
}
.codigoPri label {
  color: #607d8b !important;
  font-weight: 900 !important;
  -webkit-text-fill-color: #607d8b !important;
}

.observPri input {
  color: #000 !important;
  -webkit-text-fill-color: #000 !important;
  font-weight: 600 !important;
  border-radius: 6px;
  background-color: #06fc9521;
  border: 1px dashed #1a7f55;
  height: 21px;
}

.inputDesc {
  color: #000 !important;
  -webkit-text-fill-color: #000 !important;
  font-weight: 600 !important;
  border-radius: 6px;
  background-color: #06fc9521;
  border: 1px dashed #1a7f55;
}

.observPri label {
  color: #607d8b !important;
  font-weight: 900 !important;
  -webkit-text-fill-color: #607d8b !important;
}

label {
  font-weight: 700 !important;
}
.words {
  color: #ffffff;
  width: 100%;
  font-size: 0;
  line-height: 1.5;
}

.words span {
  font-size: 2rem;
  font-weight: 600;
  display: inline-block;
  animation: move 2s ease-in-out infinite;
}

.words2 {
  color: #ffffff;
  width: 100%;
  font-size: 0;
  line-height: 1.5;
}

.words2 span {
  font-size: 2rem;
  font-weight: 600;
  display: inline-block;
  animation: move 2s ease-in-out infinite;
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes move {
  0% {
    transform: translate(-20%, 0);
  }
  50% {
    /*  text-shadow: 0 25px 50px rgba(0, 0, 0, 0.55); */
    color: #27b279;
  }
  100% {
    transform: translate(20%, 0);
  }
}

.words span:nth-child(2) {
  animation-delay: 0.3s;
}

.words span:nth-child(3) {
  animation-delay: 0.6s;
}

.words span:nth-child(4) {
  animation-delay: 0.9s;
}

.words span:nth-child(5) {
  animation-delay: 1.2s;
}

.words span:nth-child(6) {
  animation-delay: 1.5s;
}

.words span:nth-child(7) {
  animation-delay: 1.8s;
}
.words span:nth-child(8) {
  animation-delay: 2.1s;
}
.words span:nth-child(9) {
  animation-delay: 2.4s;
}

.words2 span:nth-child(1) {
  animation-delay: 2.4s;
}
.words2 span:nth-child(2) {
  animation-delay: 2.7s;
}

.words2 span:nth-child(3) {
  animation-delay: 3s;
}

.words2 span:nth-child(4) {
  animation-delay: 3.3s;
}

.words2 span:nth-child(5) {
  animation-delay: 3.6s;
}

.words2 span:nth-child(6) {
  animation-delay: 3.9s;
}

.words2 span:nth-child(7) {
  animation-delay: 4.2s;
}
.words2 span:nth-child(8) {
  animation-delay: 4.5s;
}
.words2 span:nth-child(9) {
  animation-delay: 4.8s;
}

.openAnimation {
  -webkit-transition: all 0.3s ease !important;
  -moz-transition: all 0.3s ease !important;
  -ms-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}
.MuiDialogContent-root .MuiTabs-root {
  background-color: #0000001f !important;
  padding-top: 4px;
}
.MuiTabs-indicator {
  background-color: #27b279 !important;
}

.overflowHidden .MuiPaper-root {
  overflow: hidden !important;
}
.f10comments {
  height: calc(100vh - 350px) !important;
  margin-bottom: -5px !important;
  padding-bottom: 0 !important;
  color: #000 !important;
  opacity: 1;
  overflow-x: hidden;
  padding: 15px;
  padding-top: 0px;
  overflow-y: scroll;
  -webkit-text-fill-color: #000 !important;
}

.selectionButton {
  position: fixed;
  width: 200px;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}

.optionsTable {
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  padding-top: 10px !important;
}

.dateDisabled input[type="date"]::-webkit-inner-spin-button,
.dateDisabled input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.root {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

@-webkit-keyframes come-in {
  0% {
    -webkit-transform: translatey(100px);
    transform: translatey(100px);
    opacity: 0;
  }
  30% {
    -webkit-transform: translateX(-50px) scale(0.4);
    transform: translateX(-50px) scale(0.4);
  }
  70% {
    -webkit-transform: translateX(0px) scale(1.2);
    transform: translateX(0px) scale(1.2);
  }
  100% {
    -webkit-transform: translatey(0px) scale(1);
    transform: translatey(0px) scale(1);
    opacity: 1;
  }
}
@keyframes come-in {
  0% {
    -webkit-transform: translatey(100px);
    transform: translatey(100px);
    opacity: 0;
  }
  30% {
    -webkit-transform: translateX(-50px) scale(0.4);
    transform: translateX(-50px) scale(0.4);
  }
  70% {
    -webkit-transform: translateX(0px) scale(1.2);
    transform: translateX(0px) scale(1.2);
  }
  100% {
    -webkit-transform: translatey(0px) scale(1);
    transform: translatey(0px) scale(1);
    opacity: 1;
  }
}

.floating-container {
  position: relative;
  width: 100px;
  height: 100px;
}
.floating-container:hover {
  height: 300px;
}
.floating-container:hover .floating-button {
  box-shadow: 0 10px 25px rgba(44, 179, 240, 0.6);
  -webkit-transform: translatey(5px);
  transform: translatey(5px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.floating-container:hover .element-container .float-element:nth-child(1) {
  -webkit-animation: come-in 0.4s forwards 0.2s;
  animation: come-in 0.4s forwards 0.2s;
}
.floating-container:hover .element-container .float-element:nth-child(2) {
  -webkit-animation: come-in 0.4s forwards 0.4s;
  animation: come-in 0.4s forwards 0.4s;
}
.floating-container:hover .element-container .float-element:nth-child(3) {
  -webkit-animation: come-in 0.4s forwards 0.6s;
  animation: come-in 0.4s forwards 0.6s;
}
.floating-container .floating-button {
  position: absolute;
  width: 65px;
  height: 65px;
  background: #2cb3f0;
  bottom: 0;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin: auto;
  color: white;
  line-height: 65px;
  text-align: center;
  font-size: 23px;
  z-index: 100;
  box-shadow: 0 10px 25px -5px rgba(44, 179, 240, 0.6);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.floating-container .float-element {
  position: relative;
  display: block;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 15px auto;
  color: white;
  font-weight: 500;
  text-align: center;
  line-height: 50px;
  z-index: 0;
  opacity: 0;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
}
.floating-container .float-element .material-icons {
  vertical-align: middle;
  font-size: 16px;
}
.floating-container .float-element:nth-child(1) {
  background: #42a5f5;
  box-shadow: 0 20px 20px -10px rgba(66, 165, 245, 0.5);
}
.floating-container .float-element:nth-child(2) {
  background: #4caf50;
  box-shadow: 0 20px 20px -10px rgba(76, 175, 80, 0.5);
}
.floating-container .float-element:nth-child(3) {
  background: #ff9800;
  box-shadow: 0 20px 20px -10px rgba(255, 152, 0, 0.5);
}
